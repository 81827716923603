var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    { staticClass: "justify-content-center" },
    [
      _c(
        "CCol",
        { staticClass: "c-col", attrs: { col: "11", md: "12", lg: "11" } },
        [
          _c(
            "CCard",
            {
              staticClass: "ccard shadow-none",
              attrs: { color: "second-card-bg" },
            },
            [
              _c(
                "CCardBody",
                { staticClass: "ccard-body" },
                [
                  _c(
                    "CForm",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "cheader" }, [
                        _c(
                          "a",
                          { attrs: { href: _vm.homepage_url } },
                          [_c("logo-wrap", { attrs: { auth: true } })],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mr-3 cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    _vm.darkMode = !_vm.darkMode
                                  },
                                },
                              },
                              [
                                _vm.darkMode
                                  ? _c("CIcon", { attrs: { name: "cil-sun" } })
                                  : _c("CIcon", {
                                      attrs: { name: "cil-moon" },
                                    }),
                              ],
                              1
                            ),
                            _c("CSelect", {
                              staticClass: "mb-0",
                              attrs: {
                                value: _vm.locale,
                                options: _vm.$store.state.localeOptions,
                              },
                              on: {
                                "update:value": function ($event) {
                                  _vm.locale = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("h1", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("formRegister.title"))),
                      ]),
                      _c("p", { staticClass: "subtitle text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("formRegister.subtitle"))),
                      ]),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "12", md: "6" } },
                            [
                              _c("CInput", {
                                staticClass: "cinput",
                                attrs: {
                                  placeholder: _vm.$t("formRegister.name"),
                                  autocomplete: "username",
                                  "is-valid": _vm.detectErrorField(
                                    _vm.errors.username
                                  ),
                                  "invalid-feedback": _vm.errors.username,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function () {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-user" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.username,
                                  callback: function ($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { col: "12", md: "6" } },
                            [
                              _c("CInput", {
                                staticClass: "cinput",
                                attrs: {
                                  placeholder: _vm.$t("formRegister.email"),
                                  autocomplete: "email",
                                  "is-valid": _vm.detectErrorField(
                                    _vm.errors.email
                                  ),
                                  "invalid-feedback": _vm.errors.email,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function () {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "mail" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "12", md: "6" } },
                            [
                              _c("CInput", {
                                staticClass: "cinput",
                                attrs: {
                                  placeholder: _vm.$t("formRegister.password"),
                                  autocomplete: "password",
                                  type: "password",
                                  "is-valid": _vm.detectErrorField(
                                    _vm.errors.password
                                  ),
                                  "invalid-feedback": _vm.errors.password,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function () {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-lock-locked" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { col: "12", md: "6" } },
                            [
                              _c("CInput", {
                                staticClass: "cinput",
                                attrs: {
                                  type: "password",
                                  placeholder: _vm.$t(
                                    "formRegister.repeatPassword"
                                  ),
                                  "is-valid": _vm.detectErrorField(
                                    _vm.errors.password_confirmation
                                  ),
                                  "invalid-feedback":
                                    _vm.errors.password_confirmation,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function () {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-lock-locked" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.passwordConfirmation,
                                  callback: function ($$v) {
                                    _vm.passwordConfirmation = $$v
                                  },
                                  expression: "passwordConfirmation",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "12" } },
                            [
                              _c("ReferralCodeInput", {
                                attrs: {
                                  error: _vm.errors.referrer
                                    ? _vm.errors.referrer
                                    : "",
                                },
                                on: {
                                  "validation-error": _vm.joinReferrerError,
                                },
                                model: {
                                  value: _vm.referrer,
                                  callback: function ($$v) {
                                    _vm.referrer = $$v
                                  },
                                  expression: "referrer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { sm: "10", lg: "9", xl: "8" } },
                            [
                              _c(
                                "p",
                                { staticClass: "link-text text-muted" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("formRegister.text")) +
                                      " "
                                  ),
                                  _c(
                                    "CLink",
                                    {
                                      staticClass: "text-primary",
                                      attrs: {
                                        href: "https://cvizor.com/policy",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("formRegister.linkText"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        { staticClass: "subactions" },
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "12", sm: "6" } },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "cbtn shadow-none",
                                  attrs: { block: "", color: "dark" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("formRegister.goBack")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { col: "12", sm: "6" } },
                            [
                              _c(
                                "CLoadingButtonCustom",
                                {
                                  staticClass: "cbtn shadow-none",
                                  attrs: {
                                    block: "",
                                    color: "primary",
                                    type: "submit",
                                    loading: _vm.loadingRequest,
                                    disabled: _vm.loadingRequest,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("formRegister.signUp")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }