var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isHideInput
        ? _c(
            "CLink",
            {
              staticClass: "text-primary",
              on: {
                click: function ($event) {
                  _vm.showInput = true
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("referral.have_code")) + " ")]
          )
        : _c("CInput", {
            staticClass: "cinput mb-0",
            class: { "is-loading": _vm.loadValidation },
            attrs: {
              value: _vm.code,
              placeholder: _vm.$t("referral.input.placeholder"),
              "is-valid": !_vm.error,
              "invalid-feedback": _vm.error,
              autocomplete: "referral-code",
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-content",
                fn: function () {
                  return [_c("CIcon", { attrs: { name: "cil-code" } })]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }