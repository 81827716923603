<template>
  <div>
    <CLink
      v-if="isHideInput"
      class="text-primary"
      @click="showInput = true"
    >
      {{$t('referral.have_code')}}
    </CLink>
    <CInput
      v-else
      :value="code"
      :placeholder="$t('referral.input.placeholder')"
      :is-valid="!error"
      :invalid-feedback="error"
      class="cinput mb-0"
      :class="{'is-loading': loadValidation}"
      autocomplete="referral-code"
      @input="$emit('input', $event)"
    >
      <template #prepend-content>
        <CIcon name="cil-code" />
      </template>
    </CInput>
  </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'

export default {
  name: 'referral-code-input',
  model: {
    prop: 'code',
    event: 'input'
  },
  props: {
    code: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showInput: false,
    loadValidation: false,
    checkedRefs: []
  }),
  computed: {
    isHideInput() {
      return !this.code && !this.showInput
    },
    debounceValidateCode() {
      return debounce((referrer) => {
        this.checkedRefs.push(referrer)
        axios.post('/api/v1/register/validation/referrer', {
          referrer
        }, {
          hideErrorNotify: true
        }).then((res) => {
          if (res.status === 200) {
            this.$emit('validation-error', {})
            localStorage.setItem('inputRef', referrer)
          } else {
            throw Error('code not 200')
          }
        }).catch((e) => {
          if(e.response && e.response.status === 422){
            let ref = this.getRef(false)
            if(ref && this.checkedRefs.includes(ref)){
              ref = this.getRef(false, false)
            }
            if(ref && this.checkedRefs.includes(ref)){
              ref = this.getRef(false, false, false)
            }
            if(ref && !this.checkedRefs.includes(ref)){
              this.$emit('input', ref)
            }
          }
          this.$emit('validation-error', e)
        }).finally(() => {
          this.loadValidation = false
        })
      }, 300)
    }
  },
  watch: {
    code(newCode) {
      if (newCode.length <= 3) {
        this.loadValidation = false
        return
      }
      this.loadValidation = true
      this.validateCode(newCode)
    }
  },
  beforeMount() {
    const ref = this.getRef()
    if (ref) {
      this.$emit('input', ref)
      this.showInput = true
    }
  },
  methods: {
    validateCode(code) {
      this.debounceValidateCode(code)
    },
    getRef(fromReferral = true, fromSavedRef = true, fromCurrentRef = true, fromInputRef = true) {
      return (fromReferral && this.$route.query?.referral)
        || (fromSavedRef && localStorage.getItem('savedRef'))
        || (fromCurrentRef && localStorage.getItem('currentRef'))
        || (fromInputRef && localStorage.getItem('inputRef'))
        || this.$route.query?.ref
    },
  }
}
</script>

<style lang="scss" scoped>
.is-loading {
  /deep/ .form-control {
    background-image: url('data:image/svg+xml,%3Csvg version=\'1.1\' id=\'L9\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 100 100\' enable-background=\'new 0 0 0 0\' xml:space=\'preserve\'%3E%3Cpath fill=\'%23fff\' d=\'M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50\'%3E%3CanimateTransform attributeName=\'transform\' attributeType=\'XML\' type=\'rotate\' dur=\'1s\' from=\'0 50 50\' to=\'360 50 50\' repeatCount=\'indefinite\'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: calc(1.5em + 0.375rem) calc(1.5em + 0.375rem);
    border: unset;
  }
}
</style>
