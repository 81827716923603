<template>
  <CRow class="justify-content-center">
    <CCol class="c-col" col="11" md="12" lg="11">
      <CCard class="ccard shadow-none" color="second-card-bg">
        <CCardBody class="ccard-body">
          <CForm @submit.prevent="submit">
            <div class="cheader">
              <a :href="homepage_url">
                <logo-wrap :auth="true" />
              </a>
              <div class="d-flex align-items-center">
                <div class="mr-3 cursor-pointer" @click="darkMode = !darkMode">
                  <CIcon v-if="darkMode" name="cil-sun"/>
                  <CIcon v-else name="cil-moon"/>
                </div>
                <CSelect
                  :value.sync="locale"
                  :options="$store.state.localeOptions"
                  class="mb-0"
                />
              </div>
            </div>

            <h1 class="title">{{$t('formRegister.title')}}</h1>
            <p class="subtitle text-muted">{{$t('formRegister.subtitle')}}</p>

            <CRow>
              <CCol col="12" md="6">
                <CInput
                  v-model="username"
                  class="cinput"
                  :placeholder="$t('formRegister.name')"
                  autocomplete="username"
                  :is-valid="detectErrorField(errors.username)"
                  :invalid-feedback="errors.username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
              </CCol>
              <CCol col="12" md="6">
                <CInput
                  v-model="email"
                  class="cinput"
                  :placeholder="$t('formRegister.email')"
                  autocomplete="email"
                  :is-valid="detectErrorField(errors.email)"
                  :invalid-feedback="errors.email"
                >
                  <template #prepend-content><CIcon name="mail"/></template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" md="6">
                <CInput
                  v-model="password"
                  class="cinput"
                  :placeholder="$t('formRegister.password')"
                  autocomplete="password"
                  type="password"
                  :is-valid="detectErrorField(errors.password)"
                  :invalid-feedback="errors.password"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
              </CCol>
              <CCol col="12" md="6">
                <CInput
                  v-model="passwordConfirmation"
                  class="cinput"
                  type="password"
                  :placeholder="$t('formRegister.repeatPassword')"
                  :is-valid="detectErrorField(errors.password_confirmation)"
                  :invalid-feedback="errors.password_confirmation"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
              </CCol>
            </CRow>

            <CRow class="mb-3">
              <CCol col="12">
                <ReferralCodeInput
                  v-model="referrer"
                  :error="errors.referrer ? errors.referrer : ''"
                  @validation-error="joinReferrerError"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="10" lg="9" xl="8">
                <p class="link-text text-muted">
                  {{$t('formRegister.text')}}
                  <CLink href="https://cvizor.com/policy" target="_blank" class="text-primary">{{$t('formRegister.linkText')}}</CLink>
                </p>
              </CCol>
            </CRow>

            <CRow class="subactions">
              <CCol col="12" sm="6">
                <CButton block color="dark" class="cbtn shadow-none" @click="$router.go(-1)">{{$t('formRegister.goBack')}}</CButton>
              </CCol>
              <CCol col="12" sm="6">
                <CLoadingButtonCustom
                  block
                  color="primary"
                  class="cbtn shadow-none"
                  type="submit"
                  :loading="loadingRequest"
                  :disabled="loadingRequest"
                >
                  {{$t('formRegister.signUp')}}
                </CLoadingButtonCustom>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LogoWrap from "@/components/LogoWrap";
import ReferralCodeInput from "@/components/auth/register/referral-code-input";
import { mapActions, mapGetters } from "vuex";
import { detectErrorField, formatErrorObject } from "@/assets/js/axios/responseErrorHandler";

export default {
  name: "Register",
  components: {
    LogoWrap,
    ReferralCodeInput
  },
  data() {
    return {
      detectErrorField,
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      referrer: '',
      termsOfUse: true,
      fixedToasts: 0,
      loadingRequest: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters('user', ['homepage']),
    locale: {
      get () {
        return this.$root.$i18n.locale
      },
      set (lang) {
        this.$root.$i18n.locale = lang
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
    },
    darkMode: {
      get () {
        return this.$store.getters.darkMode
      },
      set (val) {
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'darkMode',
          value: val
        })
      }
    },
    homepage_url() {
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if(hp && hp.slice(-1) !== '/'){
        hp += '/'
      }
      if(this.locale === 'ru'){
        hp += 'ru/';
      }
      return hp;
    },
  },
  methods: {
    ...mapActions('user', ['register']),
    submit() {
      this.loadingRequest = true
      const body = {
        name: this.username,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        terms_of_use: this.termsOfUse,
        device_name: Date.now().toString()
      }

      if (this.referrer) {
        body.referrer = this.referrer.toLowerCase()
      }

      this.register(body)
        .then(({status}) => {
          if (status < 300) {
            this.errors = {}
            this.$toastr.success(this.$t('lk.account.registrationSuccessfully'))
            if (this.$store.state.user.userData.emailVerifiedAt) {
              this.$router.push(this.homepage)
            } else {
              this.$router.push({name: 'verify-email', params: { linkHasSended: true }})
            }
            if(this.$route.query['toTraining'] == 1){
              window.localStorage.setItem('toTraining', true)
            }
          }
        })
        .catch(error => {
          if (error?.response?.status === 422) {
            const errorObj = error?.response?.data?.errors
            this.errors = formatErrorObject(errorObj)
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    },
    joinReferrerError(error) {
      const errorObj = error?.response?.data?.errors
      const errorMessages = formatErrorObject(errorObj)
      this.$delete(this.errors, 'referrer')

      if (errorMessages?.referrer) {
        this.$set(this.errors, 'referrer', errorMessages.referrer)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.col-xs {
  @include media-breakpoint-down(xs) {
    flex: 0 0 16.875rem;
    max-width: 16.875rem;
  }
}
.cheader {
  display: flex;
  justify-content: space-between;
}
.ccard {
  margin: 0;

  .ccard-body {
    padding: 0;
  }
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .cinput {
    /deep/ .form-control {
      height: 2.75rem;

      @include media-breakpoint-down(lg) {
        font-size: 1rem !important;
      }
    }
  }
  .link-text {
    font-size: 0.75rem;
  }
  .cbtn {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 2.75rem;
  }

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1.875rem 0.9375rem 1.875rem;
    }
    .cheader {
      margin-bottom: 0.375rem;
    }
    .title {
      font-size: 1.5rem;
    }
    .subtitle {
      margin-bottom: 1.875rem;
    }
    .link-text {
      margin-bottom: 1.5rem;
    }
    .subactions {
      flex-direction: column-reverse;

      & > :last-child {
        margin-bottom: 0.625rem;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 3rem;
    }
    .cheader {
      margin-bottom: 1.5rem;
    }
    .subtitle {
      margin-bottom: 4.6875rem;
    }
    .link-text {
      margin-bottom: 2.5rem;
    }
  }

  @include media-breakpoint-up(md) {
    .ccard-body {
      padding: 3rem 5.125rem 3rem 6.375rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-body {
      padding: 4rem 8.625rem;
    }
    .title {
      text-align: start;
    }
    .subtitle {
      text-align: start;
      margin-bottom: 1.875rem;
    }
  }
}

.c-col {
  padding: 1rem 0;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0;
  }
}
</style>
